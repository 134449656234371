<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>
          Edit Vendor
        </h3>
      </div>
      <div class="modal-body">
        <div class="text-center mt-5" v-if="ui.loading === true">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="modal-body-container border-0" v-else>
          <div class="row">
            <div class="col-6">
              <div class="form-group full-width">
                <label for="fieldName">
                  Name
                </label>
                <input type="text" class="form-control" id="fieldName">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group full-width">
                <label for="fieldAddress">
                  Address
                </label>
                <input type="text" class="form-control" id="fieldAddress">
              </div>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group full-width">
                <label for="fieldContacts">
                  Contacts
                </label>
                <textarea name="" id="fieldContacts"
                          cols="30" rows="5">
                </textarea>
              </div>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group full-width">
                <label for="fieldCategory">
                  Category
                </label>
                <multiselect v-model="category"
                             placeholder="Select Loan"
                             id="fieldCategory"
                             class="full-width"
                             :options="categories"
                             :searchable="true"
                             :close-on-select="true"
                             :allow-empty="false"
                             :show-labels="false">
                </multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-between" v-if="ui.loading === false">
        <div>
          <button type="submit"
                  @click="saveVendor"
                  class="btn bg-primary submit">
          <span v-if="ui.saving"
                class="spinner-border spinner-border-sm"
                role="status" aria-hidden="true">
          </span>
            <span v-if="!ui.saving">Save Vendor</span>
          </button>
          <button type="reset" class="btn btn-cancel" @click="$emit('close')">
            Cancel
          </button>
        </div>
        <button class="btn btn-control">
          <img src="@/assets/icons/icon-delete.svg" alt="Delete">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'EditVendor',
  components: { Multiselect },
  data() {
    return {
      category: '',
      categories: [],
      ui: {
        loading: false,
        saving: false
      }
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;

      setTimeout(() => {
        this.ui.loading = false;
      }, 1000)
    },
    saveVendor() {
      this.ui.saving = true;

      setTimeout(() => {
        this.ui.saving = false;
      }, 1000)
    }
  },
  created() {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
.modal-body-container::v-deep .multiselect {

  &.multiselect--active {
    .multiselect__tags {
      transition: all .3s;
      border: 1px solid rgba(38,111,229,0.5);
      box-shadow: 0 0 0 4px rgba(38,111,229,0.1);
    }
  }
  &__tags {
    padding: 8px 40px 0 8px;
    transition: all .3s;
    &:hover {
      border: 1px solid rgba(38,111,229,0.2);
      box-shadow: 0 0 0 4px rgba(38,111,229,0.1);
    }
  }
  &__select {
    &:before {
      top: 15%;
      position: relative;
      right: 0;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }
  }
  &__single {
    color: rgba(0, 0, 0, 0.46);
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
  }
  &__option {
    padding: 10px 12px;
    min-height: 36px;
    transition: all .3s;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
    &--highlight {
      background: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
  }
}
</style>
