<template>
  <div>
    <div class="page-header d-flex justify-content-between align-items-center">
      <div>
        <h3 class="page-header-title">Expense Vendor</h3>
      </div>
      <button class="btn btn-primary" @click="modals.addVendor = true">
        New Vendor
      </button>
    </div>
    <div class="page-tabs d-flex justify-content-between">
      <ul class="tab-list">
        <li :class="{ 'active': tabName === 'expenses' }" @click="setTab('expenses')">
            Expenses
        </li>
        <li :class="{ 'active': tabName === 'pending' }" @click="setTab('pending')">
            Pending
        </li>
      </ul>
    </div>
    <div class="page-content">
      <div class="text-center mt-5" v-if="ui.loading === true">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-else>
        <div v-if="tabName === 'expenses'">
          <div class="search-wrapper">
            <img src="@/assets/icons/icon-search.svg" alt="Icon search">
            <input type="text" placeholder="Search">
          </div>
          <div class="table">
            <table class="table">
              <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Address</th>
                <th>Contacts</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>1</td>
                <td>Laura Vanlacker</td>
                <td>4550 45th St San Diego, CA 92115</td>
                <td>Laura Vanlacker</td>
                <td>
                  <button class="btn btn-outline-dark" @click="modals.editVendor = true">
                    Edit
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else>
          <div class="search-wrapper">
            <img src="@/assets/icons/icon-search.svg" alt="Icon search">
            <input type="text" placeholder="Search">
          </div>
          <div class="table">
            <table class="table">
              <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Address</th>
                <th>Contacts</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>1</td>
                <td>Laura Vanlacker</td>
                <td>4550 45th St San Diego, CA 92115</td>
                <td>Laura Vanlacker</td>
                <td>
                  <div class="d-flex">
                    <button class="btn btn-outline-dark" @click="approveVendor">
                      Approve
                    </button>
                    <button class="btn btn-outline-dark ms-2">
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <transition name="component-fade" mode="out-in">
      <add-new-vendor v-if="modals.addVendor === true"
                      @close="modals.addVendor = false" />
    </transition>

    <transition name="component-fade" mode="out-in">
      <edit-vendor v-if="modals.editVendor === true"
                   @close="modals.editVendor = false" />
    </transition>

  </div>
</template>

<script>
import AddNewVendor from "./modals/AddNewVendor";
import EditVendor from "./modals/EditVendor";
export default {
  name: 'ExpenseVendor',
  components: { EditVendor, AddNewVendor },
  data() {
    return {
      modals: {
        addVendor: false,
        editVendor: false
      },
      ui: {
        loading: false,
      },
      tabName: 'expenses'
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;

      setTimeout(() => {
        this.ui.loading = false;
      }, 1000);
    },
    setTab(name) {
      this.tabName = name;
    },
    approveVendor() {
      this.ui.loading = true;

      setTimeout(() => {
        this.ui.loading = false;
      }, 1000);
    }
  },
  created() {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
table.table {
  tbody {
    tr {
      vertical-align: middle;
    }
  }
}
</style>
